import React from 'react'
import ReactMarkdown from "react-markdown"
// import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql } from 'gatsby'
// import Image from "gatsby-image"

import TopMenu from '../components/topmenu'
import SEO from "../components/seo"
import Footer from "../components/footer"

const AboutTemplate = ({ data, pageContext }) => {
    // console.log("AboutTemplate: ", pageContext);
  const { frontmatter: page } = data.page;
  const slug = data.page.fields.slug;

  return (
    <section className="tar-page tar-about">
      <SEO
        title="Home"
        description={page.description}
        keywords={[`photo`, `photographer`, `Taranukhina`, `Elena`, `valokuvaaja`, `Tampere`]}
      />
      {/* <Navbar lang={content.language} slug={data.page.fields.slug} /> */}
      <TopMenu smallLogo={true} lang={page.language} languages={pageContext.languages} translation={pageContext.translation} slug={slug}></TopMenu>
      <div className="tar-section tar-article tar-about__in container-900">
        <div className="tar-about-text">
          <h2 className="tar-about-title">{page.title}</h2>
          <div className="tar-title-line"></div>
          <div className="tar-about-info ffrw">
            <ReactMarkdown source={page.content} linkTarget="_blank"/>
          </div>
        </div>
        <div className="tar-about-pic">
        {
        page.pictures.map((item, i)=>{
          return <img key={item.image} src={item.image} className={"tar-about-img-"+i} alt="Me"></img>
          // return <Image key={item.image.id} fluid={item.image.childImageSharp.fluid} className={"tar-about-img-"+i}></Image>
        })
        }
          {/* <Image className="tar-about-img" fluid={about.image.childImageSharp.fluid}></Image>
          <Image className="tar-about-img-2" fluid={about.image2.childImageSharp.fluid}></Image>
          <Image className="tar-about-img-3" fluid={about.image3.childImageSharp.fluid}></Image> */}

        </div>
      </div>
      <Footer lang={page.language} translation={pageContext.translation}></Footer>
    </section>
  )
}

export default AboutTemplate

export const indexQuery = graphql`
  query AboutById($id: String!, $language: String!) {
    page: mdx(
      id: { eq: $id },
      frontmatter: {
        type: { eq: 0 }
        templateKey: { eq: "templates/about" }
        language: { eq: $language }
      }
    ) {
      fields {
        slug
      }
      frontmatter {
        language
        title
        description
        content
        pictures {
          image
        }
      }
    }
  }
`